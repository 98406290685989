import { Injectable } from '@angular/core';
import { ApiHttpService } from '../api-http/ApiHttp.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  screen: {size,height,width,isSmall};

  constructor(
    private api: ApiHttpService
  ) {
    this.screen = { size:null,height:null,width:null,isSmall:null }
  }

  getInciso(Pos, Desc?) {
    return this.Alphabet[Pos];
  }
  getPosicionLetter(Pos) {
    return this.Alphabet[Pos];
  }

  // Screen Size
  getScreenSize(w,h) {
    if(w < 576)        return '';
    else if(w < 768)   return 'sm';
    else if(w < 992)   return 'md';
    else if(w < 1200)  return 'lg';
    else if(w < 1400)  return 'xl';
    // else return 'xxl';
  }

  getTextColorForBackground(_Hexadecimal) {
    let BackgroundType = this.lightOrDark(_Hexadecimal);
    switch(BackgroundType) {
      case 'light': return 'black';
      case 'dark':  return 'white';
      default: return null;
    }
  }
  lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {

        return 'light';
    }
    else {

        return 'dark';
    }

  }
  Promedio(_Array: any[], _Field: string) {
    return (_Array.map(item => item[_Field]).reduce((prev, next) => prev + next) / _Array.length);
  }
  max(data, attr) {
    const max = data.reduce((prev, current) => (prev[attr] > current[attr]) ? prev : current, 0);
    return max;
  }
  min(data, attr) {
    const max = data.reduce((prev, current) => (prev[attr] < current[attr]) ? prev : current, 0);
    return max;
  }
  sum(items, attr): number {
    let suma = items.reduce((a: number, b: any) => {
      const total = parseFloat(a.toFixed(2)) + parseFloat(b[attr].toFixed(2));
      return parseFloat(total.toFixed(2));
    },0);
    return suma;
  };
  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  async getBase64ImageFromURL2(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });}

  addReactivo() {}
  removeReactivo() {}

  getDateServer() {
    return this.api.POST('Sistema','ConsultarFecha',{});
  }
  FechaServidor() {
    var promise = new Promise((resolve, reject) => {
      this.api.POST('Sistema','ConsultarFecha',{}).subscribe(
        result => {
          if(!result.Error) {
            resolve(result.Fecha.Ahora);
          } else { throw result }
        },
        error => reject(null));
    });
    return promise;
  }
  getArrayFromNumber(_length: number): number[] {
    return Array.from({ length: _length }, (v, i) => i + 1);
  }

  openHTMLInTab(_HTML: string) {
    const win = window.open('about:blank', '_blank');
    win.document.write('<html><head>'+
      '<meta charset="utf-8">'+
      '<title>Nexus7</title>'+
      '<link rel="icon" type="image/x-icon" href="assets/favicon.ico">'+
      '<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">'+
      '<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" rel="stylesheet">'+
      '<link rel="stylesheet" type="text/css" href="styles.css">'+
      '<link href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&amp;subset=latin-ext" rel="stylesheet">'+
      '<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i" rel="stylesheet">'+
      '<link rel="stylesheet" href="assets/css/bootstrap.min.css">'+
      '<link rel="stylesheet" href="assets/css/animate.min.css">'+
      '<link rel="stylesheet" href="assets/fonts/fontawesome/5.11.2/all.min.css">'+
      '</head><body>');
    win.document.write('<div class="border-bottom mb-3 d-flex justify-content-center"><img height="80px" src="assets/img/logos/nx-logo.png"/></div>');
    win.document.write(_HTML);
    win.document.write('</body></html>');
    win.focus();
  }
  openURLInTab(_URL: string) {
    window.open(_URL, "_blank");
  }
  sortPersonas(Personas: any[]) {
    let sortArray =  Personas.sort(function (a, b) {
      return a.ApellidoPaterno.localeCompare(b.ApellidoPaterno) || a.ApellidoMaterno.localeCompare(b.ApellidoMaterno) || a.Nombre.localeCompare(b.Nombre);
    });
    console.log(sortArray);
    return sortArray;
  }
}
