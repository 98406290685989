import { NgModule } from '@angular/core';
import { MatPaginatorIntCustom } from './../shared/CustomClass/material-paginator';
// import {DragDropModule} from '@angular/cdk/drag-drop';

import {
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    MatOptionModule,
    MatListModule,
    MatLineModule,
    MatTooltipModule,
    MatSidenavModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatPaginatorIntl,
    MatTabsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,

    MatButtonToggleModule,
    MatCardModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTreeModule,
    MatStepperModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSortModule,
    MatBadgeModule
} from '@angular/material';


const modules = [
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatListModule,
    MatLineModule,
    MatTooltipModule,
    MatSidenavModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,

    MatButtonToggleModule,
    MatCardModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTreeModule,
    MatStepperModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSortModule,
    MatBadgeModule
];

const providers = [
    {
        provide: MatPaginatorIntl,
        useClass: MatPaginatorIntCustom
    }
]

@NgModule({
    providers: providers,
    imports: modules,
    exports: modules,
})
export class MyMaterialModule { }
