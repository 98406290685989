import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private _keys: string = '123456';

  constructor() { }

  private get config() {
    var iv = CryptoJS.enc.Utf8.parse(this._keys);
    return {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  }

  encrypt(value: string) {
    var encrypted = CryptoJS.AES.encrypt(
      value,
      this._keys,
      this.config
    );
    return encrypted.toString();
  }

  decrypt (value: string) {
    var decrypted = CryptoJS.AES.decrypt(
      value,
      this._keys,
      this.config
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}
