
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../constants';

//Moment
import * as moment from 'moment';
import { throwError, Observable } from 'rxjs';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatDialogRef, MatDialog, MatSnackBar, MatSidenav } from '@angular/material';
import { LoginDialogComponent } from '../../components/auth//login-dialog/login-dialog.component';
import { Rol } from '../DTO/Rol';
import { PerfilService } from './perfil.service';
import { Location } from '@angular/common';
import { BReactivoRespuestaOpcionMult, BReactivo, Reactivo } from '../DTO/BancoReactivos';
import { DomSanitizer } from '@angular/platform-browser';
import { ExamenReactivo, ExamenReactivoRespuestaOM, ExamenReactivoRespuesta } from '../DTO/Examen';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { BreadcrumbComponent } from 'angular-crumbs';
import { SesionService } from 'src/app/services/Sesion/Sesion.service';
moment.locale("es");



@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  public smallScreen: boolean;
  public backdrop: boolean;

  public MatDialogLoginIsOpen: boolean = false;
  public dialogRef: MatDialogRef<any>;
  public dialogLogin: MatDialogRef<LoginDialogComponent>;

  public sidenavPerfil: MatSidenav;
  public sidenavCalendario: MatSidenav;
  public sidenavPrincipal: MatSidenav;
  public openedSideNavCalendario: Observable<any>

  public MatToolbarPrincipal: boolean = true;

  public Clock = new Date();


  constructor(
      private SesionSVC: SesionService,
      private sanitizer: DomSanitizer,
      private dialog: MatDialog,
      private snackbar: MatSnackBar,
      private perfil_service: PerfilService,
      private _location: Location,
  ){}

  public getUpdateHeader(){
    return {
      headers: new HttpHeaders({
        Token: this.SesionSVC.Token,
        AreaAcademicaId: this.SesionSVC.Perfil.AreaAcademica.AreaAcademicaId.toString(),
        RolId: this.SesionSVC.Perfil.Rol.RolId.toString()
      })
      /*headers: new HttpHeaders()
      .append("Token", this.perfil_service.getToken())
      .append("AreaAcademicaId", this.perfil_service.getAreaAcademicaSesion().AreaAcademicaId.toString())
      .append("RolId", this.perfil_service.getRolSesion().RolId.toString())
      */
    }
  }


  /********************************* Toolbar Principal *********************************/
  public showToolbarPrincipal(){ this.MatToolbarPrincipal = true }
  public hideToolbarPrincipal(){ this.MatToolbarPrincipal = false }

  /******************************** DateTime Server NEXUS *****************************/
  public startClock(){
    setInterval(() => { this.Clock = new Date() }, 1);
  }
  public DateToLocale(dateString){
    //console.log(dateString);
    dateString = dateString.toString();
    dateString = dateString.split(' ').slice(0, 5).join(' ');
    //console.log(dateString);
    return dateString;
  }

  /************************************* SIDENAV **************************************/
  public toggleSidenavPrincipal(){
    console.log("toggle Perfil");
    this.sidenavPrincipal.opened = !this.sidenavPrincipal.opened
  }
  public toggleSideNavPerfil(){
    console.log("toggle Perfil");
    this.sidenavPerfil.opened = !this.sidenavPerfil.opened;
  }
  public initCalendarioSideNav(sideNav){
    console.log("init calendario");
    this.sidenavCalendario = sideNav;

    this.sidenavCalendario.openedStart.subscribe( result => {
      console.log("Opened Calednario");
    });
    this.openedSideNavCalendario = this.sidenavCalendario.openedStart;
  }


  public toggleSideNavCalendario(){
    console.log("toggle Calendario");
    this.sidenavCalendario.opened = !this.sidenavCalendario.opened;
  }
  public toggleSidenavVideoTutoriales(){
    this.sidenavPerfil.opened = !this.sidenavPerfil.opened
  }
  public startOpenedSideNavCalendario(){

  }

  /*********************************** CONSTANTS **********************************/

  public getCursoEstado(id){ return (CONSTANTS.CURSO_ESTADOS).find(CE => CE.valor == id) }
  public getCursoEstados(){ return CONSTANTS.CURSO_ESTADOS }

  public getModelo(id){ return (CONSTANTS.MODELOS).find(M => M.ModeloId == id) }
  public getModelos(){ return CONSTANTS.MODELOS }

  public getModalidad(id){ return (CONSTANTS.MODALIDADES).find(M => M.ModalidadId == id) }
  public getModalidades(){return CONSTANTS.MODALIDADES }

  public getTipoEstructura(id){ return (CONSTANTS.TIPO_ESTRUCTURA).find(T => T.TipoEstructuraId == id) }
  public getTiposEstructura(){return CONSTANTS.TIPO_ESTRUCTURA }

  /********************************* Search ********************************/
  public Find_NameUsername_List(_List,_Text){
    return _List.find(Est =>
      Est.Matricula.toLowerCase().includes(_Text.toLowerCase()) ||
      (Est.Nombre+" "+Est.ApellidoPaterno+" "+Est.ApellidoMaterno).toLowerCase().includes(_Text.toLowerCase()) ||
      (Est.Nombre.split(" ")[0]+" "+Est.ApellidoPaterno).toLowerCase().includes(_Text.toLowerCase()) ||
      (Est.ApellidoPaterno+" "+Est.ApellidoMaterno+" "+Est.Nombre).toLowerCase().includes(_Text.toLowerCase()) ||
      (Est.ApellidoPaterno+" "+Est.Nombre).toLowerCase().includes(_Text.toLowerCase()));
  }


  /*********************************** Reactivos  *******************************/
  public getInciso(Posicion){
    return CONSTANTS.Alphabet[Posicion -1];
  }
  public getIncisoDerecha(Respuestas, DerechaId){
    let respuesta = Respuestas.find(R => R.ReactivoRespuestaRelacionarDerechaId == DerechaId)
    let index = respuesta ? (respuesta.Posicion - 1) : null;
    return respuesta ? CONSTANTS.Alphabet[index] : null;
    // return CONSTANTS.Alphabet[Respuestas.find(R => R.ReactivoRespuestaRelacionarDerechaId == DerechaId).Posicion -1];
  }

  /************************************ MATH **************************************/
  public getSumatoria = function(items, attr){
    let suma = items.reduce((a, b) => parseFloat(a) + parseFloat(b[attr]),0);
    return suma;
  };

  public getPromedio = function(items, attr){
    let total = this.getSumatoria(items, attr) / items.length;
    return total;
  };

  /************************************ DatePicker ***************************/
  public getDatePickerSettings(opt) {
    let Settings = {
      BigBanner: true,
      timePicker: true,
      format: 'dd-MM-yyyy hh:mm a',
      defaultOpen: true,
      closeOnSelect: true
    };
    switch (opt) {
      case 1: break;
      case 2: Settings.BigBanner = false;
              Settings.defaultOpen = false;
              break;
    }
    return Settings;
  }
  /*********************************** DateTime ******************************/
  public ObtenerDiferenciaEntreFechas(FechaInicio, FechaFin) {
    const F1 = moment(FechaInicio);
    const F2 = moment(FechaFin);
    const Diferencia = F2.diff(F1, 'seconds');
    console.log(Diferencia);
    let Numero;
    let Unidad;
    if (Diferencia < 60) {
        Numero = Diferencia % 60;
        Unidad = 'min';
    } else if (Diferencia < 3600 ) {
      Numero = Diferencia % 60;
      if (Numero === 1) Unidad = 'min';
      else Unidad = 'min';
    } else if (Diferencia < 86400) {
      Numero = Diferencia % 3600;
      if (Numero === 1) Unidad = 'hora';
      else Unidad = 'horas';
    } else if (Diferencia < 604800) {
      Numero = Diferencia % 86400;
      if (Numero == 1) Unidad = 'dia';
      else Unidad = 'dias';
    } else if (Diferencia < 31557600) {
      Numero = Diferencia % 604800;
      if (Numero == 1) Unidad = 'semana';
      else Unidad = 'semanas';
    } else {
      Numero = Diferencia % 31557600;
      if (Numero == 1) Unidad = 'año';
      else Unidad = 'años';
    }
    const D = 'Hace ' + Numero + ' ' + Unidad;
    console.log(D);
    return D;

  }
  public getDiffDates(DateStart, DateEnd){
    let Date1 = moment(DateStart);
    let Date2 = moment(DateEnd);

    let Diff = Date2.diff(Date1, "seconds");
    console.log(Diff);
    return Diff;
  }
  public CheckSameDayDates(Date1,Date2){
    let validation = moment(Date1).isSame(Date2, 'day');
    //console.log(validation);
    return validation
  }
  public CheckSameWeekDates(Date1,Date2){
    let validation = moment(Date1).isSame(Date2, 'week');
    //console.log(validation);
    return validation
  }

  /************************************ FORMATS ***********************************/
  public capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  public getMomentFormat(date, format){
    return moment(date).format(format);
  }

  public getDateFormat(date,format){
    let fecha_result = "";
    if(date){
      //let f = new Date(date);
      switch(format){
        case 1:// 09/SEPTIEMBRE/2018
          fecha_result = moment(date,"DD-MM-YYYY",true).format("DD/MMMM/YYYY").toUpperCase();
          break;
        case 2:// 31-12-2018
          fecha_result = moment(date).format("DD-MM-YYYY").toUpperCase();
          break;
        case 3:// 20/09/2018
          fecha_result = moment(date).format("DD/MM/YYYY").toUpperCase();
          break;
        case 4:
          var dia = moment(date).format("dddd DD");
          var mes = moment(date).format("MMMM");
          fecha_result = this.capitalize(dia) + ", " + this.capitalize(mes);
          break;
        case 5:
          fecha_result = date;
          break;
      }
    }
    return fecha_result;
  }

  public getTimeFormat(date,format){
    let fecha_result = "";
    if(date){
      //let f = new Date(date);
      switch(format){
        case 1:// 05:00 pm
          fecha_result = moment(date).format("hh:mm a");
          break;
        case 2:// 31-12-2018
          fecha_result = moment(date).format("HH:mm");
          break;
      }
    }
    return fecha_result.toUpperCase();
  }

  public getDateTimeFormat(date, format, time24){
    //time_format == true => 24hr
    //time_format == false => AM/PM
    let datetime;
    switch(format){
      case 1:// 09/SEPTIEMBRE/2018 12:00 PM
        datetime = time24 ? moment(date).format("DD/MMMM/YYYY HH:mm") : moment(date).format("DD/MMMM/YYYY hh:mm a");
        break;
      case 2:// 31-12-2018 12:00 PM
        datetime = time24 ? moment(date).format("DD-MM-YYYY HH:mm") : moment(date).format("DD-MM-YYYY hh:mm a");
        break;
      case 3:// 20/09/2018 12:00 PM
        datetime = time24 ? moment(date).format("DD/MM/YYYY HH:mm") : moment(date).format("DD/MM/YYYY hh:mm a");
        break;
      case 4:// SEPTIEMBRE 20, 2018 12:00 PM
        datetime = time24 ? moment(date).format("DD/MM/YYYY HH:mm") : moment(date).format("DD/MM/YYYY hh:mm a");
        break;
      case 5:// 09/SEP/2018 12:00 PM
        datetime = time24 ? moment(date).format("DD/MMM/YYYY HH:mm") : moment(date).format("DD/MMM/YYYY hh:mm a");
        let array = datetime.split(".")
        datetime = (array[0] + array[1]).toUpperCase();
        break;
      case 6:// Lunes 09 de Septiembre del 2018 a las 12:00 PM
        datetime = this.capitalize(moment(date).format("dddd DD [de ]")) + this.capitalize(moment(date).format("MMMM [del] YYYY [a las] HH:mm:a"))
        break;
    }
    return datetime
  }

  /************************************* MONTHS  ****************************************/
  public getMeses(){
    return CONSTANTS.MESES;
  }

  /************************************ SNACKBAR **************************************/
  public snackbarSucces(message,duration){
    this.snackbar.open(message, "X", {
      duration: duration,
      panelClass: ["snackbar-success","action-close"],
      verticalPosition: 'bottom',
    });
  }
  public snackbarError(message,duration){
    this.snackbar.open(message, "X", {
      duration: duration,
      panelClass: ["snackbar-error","action-close"],
      verticalPosition: 'bottom',
    });
  }

  /************************************* CursoImagenes ***********************************/
  public getImageCurso(C){
    let images = [
      {CursoId: null, Image: "https://image.posta.com.mx/sites/default/files/sdxcv.jpg"},
      {CursoId: 214230, Image: "https://cdn.kastatic.org/googleusercontent/pmSFOg8sp5pAK3K1oP7KAB3bckl1uYej2PfZtGQI3LDQ4jsLzkMhDNfEA6bxiUUGFt2aQUgwwKhPINWpzy5F7sD0"},
      {CursoId: 224242, Image: "https://image.slidesharecdn.com/electricidadymagnetismo1-130709192314-phpapp01/95/electricidad-y-magnetismo-1-1-638.jpg?cb=1373397855"},
      {CursoId: 214231, Image: "https://www.definicionabc.com/wp-content/uploads/estadistica.jpg"},
      {CursoId: 214232, Image: "http://www.die.uma.es/archivos/electromagnetismo2.jpg"},
      {CursoId: 214233, Image: "https://cdn.pruebat.org/recursos/recursos/matematicas.png"},

      {CursoId: 274286, Image: "./assets/img/cursos/274286.jpg"},
      {CursoId: 274347, Image: "./assets/img/cursos/274347.jpg"},
      {CursoId: 274358, Image: "./assets/img/cursos/274358.jpg"},
      {CursoId: 274388, Image: "./assets/img/cursos/274388.jpg"},
      {CursoId: 274389, Image: "./assets/img/cursos/274389.jpg"},
      {CursoId: 274396, Image: "./assets/img/cursos/274396.jpg"},
    ];
    let imagen = images.find(I => I.CursoId == C.CursoId);
    if(!imagen){
      imagen = images.find(I => I.CursoId == null);
    }
    //return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    return imagen;
  }

  public getImageCurso2(ImagenId, CursoId){
    let image;

    if(ImagenId) image = CONSTANTS.CURSO_IMAGENES.find(I => I.ImagenId == ImagenId);
    if(CursoId) image = CONSTANTS.CURSO_IMAGENES.find(I => I.CursoId == CursoId);

    return image ? image : CONSTANTS.CURSO_IMAGENES[0];
  }

  /************************************ BACKDROP **********************************/
  public showBackdrop(){ this.backdrop = true}
  public hideBackdrop(){ this.backdrop = false}

  /************************************** DIALOGS *************************************/
  public openDialogLogin(){
    this.dialogRef = this.dialog.open(LoginDialogComponent);

    this.dialogRef.afterOpen().subscribe(result => {
      console.log(`Dialog Login Open result: ${result}`);
      this.MatDialogLoginIsOpen = true;
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog Login Close result: ${result}`);
      this.MatDialogLoginIsOpen = false;
    });
  }

  public openDialogLogin2(){
    if(this.dialogLogin) this.dialogLogin.close();
    this.dialogLogin = this.dialog.open(LoginDialogComponent);
    this.dialogLogin.afterOpen().subscribe(result => { this.MatDialogLoginIsOpen = true });
    this.dialogLogin.afterClosed().subscribe(result => { this.MatDialogLoginIsOpen = false });
    return this.dialogLogin.afterClosed().pipe(
      map(data => data),
      catchError(error => error)
    );
  }

  public sesionExpired(){
    return this.openDialogLogin2().pipe(
      map(data => {
        if(data.SesionInit){
          return true;
        }
      }),
      catchError(error => error)
    )
  }

  public isSesionExpired(Code){
    if(Code == 2004 || Code == 2011){ return true }
    else{ return false }
  }

  /**************************************** CSV  ***************************************/
  public readFileCSV(file: any): void {
    var fileReader: FileReader = new FileReader();
    fileReader.onloadend =  (e:Event) => {
      console.log(e);
      return this.extractFileDataCSV(fileReader.result);
    }
    fileReader.readAsText(file);
  }
  public extractFileDataCSV(csvData){
    console.log("Leyendo");
    let allTextLines = csvData.split(/\r\n|\n/);
    let headers = allTextLines[0].split(',');
    let result = [];

    for (let i = 1; i < allTextLines.length; i++) {
        // split content based on comma
        let line = allTextLines[i].split(',');
        console.log(line);
        if (allTextLines[i] != "") {

            //result.push(U);
            result.push(line);
        }

    }
    console.log("Termine");
    console.log(result);
    return result;
  }

  /*************************************** Orden *****************************************/
  public getLetterSeparator(List, Item, index, Property, DESC){
    let Letter;
    if(index > 0){
      if(List[index][Property].toString()[0].toUpperCase() != List[index - 1][Property].toString()[0].toUpperCase()){
        Letter = List[index][Property].toString()[0];
        return Letter.toUpperCase();
      }else{
        return false;
      }
    }else{
      Letter = Item[Property].toString()[0];
      return Letter.toUpperCase();
    }

  }

  /*************************************** FROALA *****************************************/

  public getFroalaOptions(button_opt,minheight,maxheight,placeholder){
    let FroalaButtons = [];
    switch(button_opt){
      case 1://All
              FroalaButtons = null;
              /*FroalaButtons = ['bold', 'italic', 'underline','subscript', 'superscript', '|',
              'fontSize','fontFamily','color','paragraphFormat','paragraphStyle','inlineClass', 'lineHeight', '|',
              'align', 'formatOL', 'formatUL', 'outdent','indent', '-',
              'insertLink','insertImage','insertVideo','insertFile', 'insertTable', '|',
              'emoticons', 'specialCharacters', 'insertHR', 'selectAll','clearFormatting','html'
            ];*/
            break;
      case 2://Reactivos
              FroalaButtons = ['bold','italic','underline','|','subscript','superscript','fontSize','color','align',
              '-',
              'formatOL','formatUL','outdent','indent', '|',
              'insertImage','insertTable','|','emoticons','specialCharacters','html'
            ];
            break;
      case 3://Solo Texto
            break;
      case 3://Textarea
            break;
    }
    return {
      language: 'es',
      placeholderText: placeholder,
      minheight: minheight,
      maxheight: maxheight,
      iconsTemplate: 'font_awesome_5',
      toolbarButtons: FroalaButtons,
      quickInsertEnabled: false,
      charCounterCount: true,
      imageUpload: true,
      events: {
        'froalaEditor.image.beforeUpload':  function  (e,  editor,  images) {
          if(images.length) {
            const  reader  =  new  FileReader();
            reader.onload  =  (ev)  =>  {
              const  result  =  ev.target['result'];
              editor.image.insert(result,  null,  null,  editor.image.get());
              console.log(ev,  editor.image,  ev.target['result'])
            };
            // Read image as base64.
            reader.readAsDataURL(images[0]);
          }
          return  false;
        }
      },
    }

  }

  /************************************* File AIKEN **************************************/

  public getReactivosExamenFileAIKEN(data, ExamenId){
    let Lines = data.split(/\r\n|\n/);
    let Preguntas: ExamenReactivoRespuesta[] = [];
    let Pregunta = new ExamenReactivoRespuesta(null);

    let PosicionReactivo = 0;
    let PosicionRespuesta = 0;
    let ExpRegPregunta = /^[A-Z][.][" "]*/;

    for (let i = 0; i < Lines.length; i++) {
      let Line = Lines[i];
      if(Line != ""){
        if(Line.match(ExpRegPregunta)){
          //Opciones de Respuesta
          PosicionRespuesta++;
          Pregunta.ReactivoRespuestaOpcionMultiple.push( new ExamenReactivoRespuestaOM({
            ReactivoRespuestaOpcionMultipleId: 0,
            Respuesta: Line.split(". ")[1],
            ReactivosId: 0,
            Posicion: PosicionRespuesta,
            EsCorrecta: false,
            Estado: true
          }));
        }else{
          if(Line.startsWith("ANSWER: ")){
            // Linea que indica la Respuesta Correcta
            let ANSWER = Line.split(":")[1].trim();
            let OpcionCorrecta = CONSTANTS.Alphabet.indexOf(ANSWER);
            Pregunta.ReactivoRespuestaOpcionMultiple[OpcionCorrecta].EsCorrecta = true;
            Preguntas.push(Pregunta);
            Pregunta = new ExamenReactivoRespuesta(null);
            PosicionRespuesta = 0;
          }else{
            // Pregunta
            PosicionReactivo ++;
            Pregunta.Reactivo = new ExamenReactivo({
              ReactivosId: 0,
              ExamenId: ExamenId,
              TipoReactivoId: 2,
              Posicion: PosicionReactivo,
              Descripcion: Line,
              Estado: true
            });
          }
        }
      }
    }
    return Preguntas;

  }

  public get_reactivos_from_file_AIKEN(data, BancoReactivosId){
    //Tipo == 1 BancoReactivos,
    //Tipo == 2 Examen

    let Pregunta = new BReactivo(null);

    console.log("Leyendo");
    let allTextLines = data.split(/\r\n|\n/);
    let result = [];


    let PosicionReactivo = 0;
    let PosicionRespuesta = 0
    for (let i = 0; i < allTextLines.length; i++) {
        console.log(i)
        let line = allTextLines[i];

        if(line != ""){
          // Valida si Es una Respuesta
          if(line.match(/^[A-Z][.][" "]*/)){
            //OPCIONES DE RESPUESTA
            PosicionRespuesta++;
            Pregunta.ReactivoRespuestaOpcionMultiple.push(new BReactivoRespuestaOpcionMult({
              ReactivoRespuestaOpcionMultipleId: 0,
              Respuesta: line.split(". ")[1],
              ReactivosId: 0,
              Posicion: PosicionRespuesta,
              EsCorrecta: false,
              Estado: true
            }))
          }else{
            //Valida si es la Respuesta Correcta
            if(line.startsWith("ANSWER: ")){
              //RESPUESTA CORRECTA
              let ANSWER = line.split(":")[1].trim();
              let correcta = CONSTANTS.Alphabet.indexOf(ANSWER);
              Pregunta.ReactivoRespuestaOpcionMultiple[correcta].EsCorrecta = true;
              result.push(Pregunta);
              Pregunta = new BReactivo(null);
              PosicionRespuesta = 0;
            }else{
              //PREGUNTA
              console.log(`${PosicionReactivo}.- ${line}`);
              PosicionReactivo ++;
              Pregunta.Reactivo = new Reactivo({
                ReactivosId: 0,
                BancoReactivosId: BancoReactivosId,
                TipoReactivoId: 2,
                Posicion: PosicionReactivo,
                Descripcion: line,
                Estado: true
              });
            }

          }
        }else{
          console.log("Separador en Blanco");
          // Quiere decir que ya termino la pregunta, y seguimos buscando una nueva respuesta

        }

    }
    console.log(result);
    return result;
  }

  public getTipoReactivo(TipoReactivoId){
    return CONSTANTS.TiposReactivo.filter(TR => TR.TipoReactivoId == TipoReactivoId)[0];
  }

  /*********************************** PERIODOS ****************************************/
  public getTipoPeriodo(Tipo){
    return CONSTANTS.TiposPeriodo.find(TP => TP.Tipo === Tipo);
  }


  /**************************************** BACK **************************************/
  public goBack(){
    this._location.back();
  }

  /*********************************** Request Methods ***********************/
  public handleResult(result){
    if (result) {
      if (!result.Code && (result.Code == 2004 || result.Code == 2003 || result.Code == 2011)){
        this.perfil_service.getPerfil().Sesion.isExpired = true;
        return {
          Result: false,
          ErrorId: !result.Code,
          ErrorCode: -1,
          Message: "Sesión Expirada"
        };
      } else {
        return result;
      }
    } else {
      console.log("Sin Respuesta");
      return {
        Result: false,
        Code: -2,
        ErrorId: -2,
        ErrorCode: null,
        Message: "No se obtuvo una respuesta del Servidor"
      };
    }
  }
  public handleError(error: HttpErrorResponse) {
      console.log(error);
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('Ocurrio un problema:', error.error.message);
        //this.snackbarError('Ocurrio un problema:'+ error.error.message, 5000);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Codigo:  ${error.status}, ` +
          `Mensaje: ${error.error}`);
        //this.snackbarError(`WebAPI Code: ${error.status}, `,5000);
      }
      // return an observable with a user-facing error message
      return throwError(error);
  };
}
