import { EncuestaReactivo } from "./EncuestaReactivo";

export class EncuestaReactivoEjecucion extends EncuestaReactivo {
  EjecucionReactivoAbierto: EncuestaReactivoEjecucionTextoAbierto;
  EjecucionReactivoVerdaderoFalso: EncuestaReactivoEjecucionVerdaderoFalso;
  EjecucionReactivoOpcionMultiple: EncuestaReactivoEjecucionOpcionMultiple
  EjecucionReactivoRespuestaMultipleDTO: EncuestaReactivoEjecucionRespuestaMultiple [] = [];

  constructor(R){
    super(R);
    if(R){
      this.EjecucionReactivoAbierto = R.EjecucionReactivoAbierto;
      this.EjecucionReactivoVerdaderoFalso = R.EjecucionReactivoVerdaderoFalso;
      this.EjecucionReactivoOpcionMultiple = R.EjecucionReactivoOpcionMultiple;
      this.EjecucionReactivoRespuestaMultipleDTO = R.EjecucionReactivoRespuestaMultipleDTO.map(ER => new EncuestaReactivoEjecucionRespuestaMultiple(ER));
    }
  }
}
export abstract class EncuestaReactivoEjecucionBase {
  EjecucionReactivo?: boolean;
  EncuestaReactivoRespuestaId?: boolean;
  Estado: boolean;
  constructor(E) {
    if (E) {
      this.EjecucionReactivo = E.EjecucionReactivo ? E.EjecucionReactivo : null;
      this.EncuestaReactivoRespuestaId = E.EncuestaReactivoRespuestaId ? E.EncuestaReactivoRespuestaId : null;
      this.Estado = E.Estado;
    }
  }
}
export class EncuestaReactivoEjecucionVerdaderoFalso extends EncuestaReactivoEjecucionBase {
  EjecucionReactivoVerdaderoFalsoId: number;
  constructor(R){
    super(R);
    this.EjecucionReactivoVerdaderoFalsoId = R.EjecucionReactivoVerdaderoFalsoId ? R.EjecucionReactivoVerdaderoFalsoId : null;
  }
}
export class EncuestaReactivoEjecucionOpcionMultiple extends EncuestaReactivoEjecucionBase {
  EjecucionReactivoOpcionMultipleId: number;
  constructor(R){
    super(R);
    this.EjecucionReactivoOpcionMultipleId = R.EjecucionReactivoOpcionMultipleId ? R.EjecucionReactivoOpcionMultipleId : null;
  }
}
export class EncuestaReactivoEjecucionRespuestaMultiple extends EncuestaReactivoEjecucionBase {
  EjecucionReactivoRespuestaMultipleId: number;
  constructor(R){
    super(R);
    this.EjecucionReactivoRespuestaMultipleId = R.EjecucionReactivoRespuestaMultipleId ? R.EjecucionReactivoRespuestaMultipleId : null;
  }
}
export class EncuestaReactivoEjecucionTextoAbierto extends EncuestaReactivoEjecucionBase {
  EjecucionReactivoAbiertoId: number;
  constructor(R){
    super(R);
    this.EjecucionReactivoAbiertoId = R.EjecucionReactivoAbiertoId ? R.EjecucionReactivoAbiertoId : null;
  }
}
