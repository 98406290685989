import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map,tap, catchError, switchMap } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import {Subject} from 'rxjs/Subject';

import { SesionService } from '../../Sesion/Sesion.service';
import { SeguridadService } from '../../Seguridad/Seguridad.service';
import { LoginDialogComponent } from 'src/app/components/auth/login-dialog/login-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';


import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { LoggerService } from '../../Utils/logger.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  private Sesion = new Subject();
  private DialogLogin: MatDialogRef<any>;
  private DialogLoginOpen = false;

  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$      = this.tokenRefreshedSource.asObservable();
  RequestList = [];

  constructor(
    private logger: LoggerService,
    private SeguridadSVC: SeguridadService,
    private SesionSVC: SesionService,
    private dialog: MatDialog
  ) { }




  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log(req);
    /*next.handle(req).subscribe(
      result => ,
      error =>
    );*/
    return next.handle(req).pipe(
      switchMap(event => {
          if (event.type === HttpEventType.Response && this.SesionSVC.isTokenExpirado(event.body.Code)) {
            /*this.DialogLogin = this.dialog.open(LoginDialogComponent,{disableClose: false});
            this.DialogLogin.afterOpen().subscribe(() => { this.DialogLoginOpen = true });
            return this.DialogLogin.afterClosed().pipe(
              tap(sesion => {
                console.log(sesion);
                return next.handle(req);
              }),
              catchError(error => throwError(error))
            );*/

            this.logger.info('<--------------------------------- Interceptor ------------------------->');
            let headers = new HttpHeaders({
              'Token': this.SesionSVC.Token,
              'AreaAcademicaId': this.SesionSVC.Perfil.AreaAcademica.AreaAcademicaId.toString(),
              'RolId': this.SesionSVC.Perfil.Rol.RolId.toString(),

            });
            let ReqClone = req.clone({headers});
            this.RequestList.push(ReqClone);
            //console.log(this.RequestList);
            return this.showLoginModal(event).pipe(
              switchMap(sesion => {
                return next.handle(req);
              }));
            } else {
              return new Observable<any>((observer) => observer.next(event));
            }
      }));
  }

  showLoginModal(event): Observable<any> {
    console.log('Dialog Login');

      this.DialogLogin = this.dialog.open(LoginDialogComponent,{disableClose: false});
      this.DialogLogin.afterOpen().subscribe(() => { this.DialogLoginOpen = true });
      return new Observable<any>((observer) => {
          this.DialogLogin.afterClosed().subscribe(result => {
            //console.log('The dialog was closed', result);
            observer.next(result);
          });
      });

  }

/*
  refreshToken(event) {
      //only watch refreshTokenInProgress if it was not login request
    if (!event.url.includes('CrearSesion')) {
          return new Observable(observer => {
              this.tokenRefreshed$.subscribe(() => {
                  observer.next();
                  observer.complete();
              });
          });
      } else {
          this.refreshTokenInProgress = true;
          return this.showLoginModal(event).pipe(
              tap(() => {
                  console.log('setting refresh status to false');
                  this.refreshTokenInProgress = false;
                  this.tokenRefreshedSource.next();
              }));

      }
  }*/
}

/*
if(event.body.Code) {
            if(this.SesionSVC.isTokenExpirado(event.body.Code)) {

                this.DialogLogin = this.dialog.open(LoginDialogComponent,{disableClose: true});
                //return this.DialogLogin.afterClosed();
                return this.DialogLogin.afterClosed().pipe(
                  map(data => {
                    console.log('Login Dialog Closed');
                    console.log(data);
                    return Next.handle(Request);
                  },
                  catchError(error => throwError(error))
                ));
              /*this.SesionSVC.DialogSesionExpirada().subscribe(
                sesion => Next.handle(Request),
                error => console.log(error)
              );*/
              /*return this.SesionSVC.DialogSesionExpirada().pipe(
                map(sesion => {
                  console.log('Dialog Sesion success');
                  console.log(Request);
                  Next.handle(Request);
                  //return sesion;
                }),
                catchError(error => throwError(error))
              )
            }
          } else {
            console.log('Sin errores');
          }
           */


/*

    const Sesion = this.SesionSVC
    //const TokenExpired = this.SesionSVC.TokenExpired();
    if (!Request.url.includes('/Seguridad/')) {
      console.log('Call Api Seguridad');
    }
    return Next.handle(Request).pipe(
      map(event => {
        console.log(event);
        if (event instanceof HttpErrorResponse) {
          if (this.SesionSVC.isTokenExpirado(event)) {
            return this.SesionSVC.DialogSesionExpirada().pipe(
              map(sesion => sesion ? Next.handle(Request) : false)
            );
          }
        }
      }),
      catchError((error, caught) => {
        if (error instanceof HttpErrorResponse) {
          if (this.SesionSVC.isTokenExpirado(error)) {
            return this.SesionSVC.DialogSesionExpirada().pipe(
              map(() => {
                return Next.handle(Request);
              })
            );
          } else {
            return throwError(error);
          }
        }
        return caught;
      })
    );


    if (Request.url.endsWith('ReactivarSesion')) {
      return Next.handle(Request);
    } else {
      return Next.handle(Request).pipe(
        catchError((error, caught) => {
          if (error instanceof HttpErrorResponse) {
            if (this._checkTokenExpiryErr(error)) {
              return this._ifTokenExpired().pipe(
                map(() => {
                  return Next.handle(Request);
                })
              );
            } else {
              return throwError(error);
            }
          }
          return caught;
        })
      );
    }
  }

}
*/
