import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Encuesta } from 'src/app/models/Encuesta/Encuesta';
import { EncuestaService } from 'src/app/services/Encuesta/Encuesta.service';
import { EncuestaResponderComponent } from '../../encuesta-responder/encuesta-responder.component';

@Component({
  selector: 'app-encuestas-modal',
  templateUrl: './encuestas-modal.component.html',
  styleUrls: ['./encuestas-modal.component.css']
})
export class EncuestasModalComponent implements OnInit {

  public Encuestas: Encuesta[];

  constructor(
    private EncuestaSVC: EncuestaService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private dialog: MatDialogRef<EncuestasModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    console.log(data);
    this.Encuestas = data;
  }

  ngOnInit() {
  }

  selectEncuesta(_Encuesta: Encuesta) {
    this.matDialog.open(EncuestaResponderComponent, {
      data: {
        Encuesta: _Encuesta
      }
    }).afterClosed().subscribe(res => {
      const index = this.Encuestas.findIndex(encuesta => encuesta.EncuestaId == _Encuesta.EncuestaId);
      switch(res.accion) {
        case 'eliminar':
          this.Encuestas.splice(index, 1);
          break;
      }
      if(this.Encuestas.length === 0) {
        this.dialog.close();
      }
    });
  }



  close() {
    this.dialog.close();
    if(this.Encuestas && this.Encuestas.length > 0) {
      this.router.navigate(['App','UnidadesAprendizaje']);
    }
  }

}
