export class EncuestaReactivoRespuesta {
  EncuestaReactivoRespuestaId: number;
  Descripcion: number;
  Posicion: number;
  Estado: boolean;

  constructor(R){
    if(R){
      this.EncuestaReactivoRespuestaId = R.EncuestaReactivoRespuestaId;
      this.Descripcion = R.Descripcion;
      this.Posicion = R.Posicion;
      this.Estado = R.Estado;
    }
  }
}

export class EncuestaReactivoRespuestaVerdaderoFalso extends EncuestaReactivoRespuesta{
  EncuestaReactivoRespuestaVerdaderoFalsoId: number;
  TipoRespuestaVerdaderoFalso: TipoRespuestaVerdaderoFalso;
  Estado: boolean;
  constructor(R){
    if(R){
      super(R);
      this.EncuestaReactivoRespuestaVerdaderoFalsoId = R.EncuestaReactivoRespuestaVerdaderoFalsoId;
      this.TipoRespuestaVerdaderoFalso = new TipoRespuestaVerdaderoFalso(R.TipoRespuestaVerdaderoFalso);
      this.Estado = R.Estado;
    }
  }
}

export class TipoRespuestaVerdaderoFalso{
  TipoRespuestaVerdaderoFalsoId: number;
  NombreVerdadero: string;
  NombreFalso: string;
  Estatus: false;
  constructor(R){
    if(R){
      this.TipoRespuestaVerdaderoFalsoId = R.TipoRespuestaVerdaderoFalsoId;
      this.NombreVerdadero = R.NombreVerdadero;
      this.NombreFalso = R.NombreFalso;
      this.Estatus = R.Estatus;
    }
  }
}
