import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { ApiHttpService } from '../api-http/ApiHttp.service';

const TIPO_DESTINO = [
  { TipoDestinoId: 1, Nombre: "Área Académica" },
  { TipoDestinoId: 2, Nombre: "Unidad de Aprendizaje" },
  /*{ TipoDestinoId: 3, Nombre: "Cuenta" }*/

];
@Injectable({
  providedIn: 'root'
})
export class AvisoService {
  public Dominio = 'Aviso';
  private _TiposDestino = TIPO_DESTINO;

  constructor(
    private API: ApiHttpService
  ) {}

  get TiposDestino() {
    return this._TiposDestino;
  }

  /****************** API ****************/
  public ConsultarAvisos(CursoId, Asignados): Observable<any> {
    return this.API.POST(this.Dominio, 'ConsultarAvisos', {
      CursoId: CursoId,
      Asignados: Asignados
    });
  }
  public ConsultarAviso(AvisoId) {
    return this.API.POST(this.Dominio, 'ConsultarAviso', {
      AvisoId: AvisoId
    });
  }
  public ActualizarAvisoCurso(Aviso){
    return this.API.POST(this.Dominio, 'ActualizarAvisoCurso', {
      Aviso: Aviso
    });
  }
  public ActualizarAvisoAreaAcademica(Aviso){
    return this.API.POST(this.Dominio, 'ActualizarAvisoAreaAcademica', {
      Aviso: Aviso
    });
  }
  public EliminarAvisoCurso(AvisoId) {
    return this.API.POST(this.Dominio, 'EliminarAvisoCurso', {
      AvisoId: AvisoId
    });
  }
  public EliminarAvisoAreaAcademica(AvisoId) {
    return this.API.POST(this.Dominio, 'EliminarAvisoAreaAcademica', {
      AvisoId: AvisoId
    });
  }
  public CopiarAviso(Aviso, CursoId, AvisoOrigenId) {
    return this.API.POST(this.Dominio, 'Copiaraviso', {
      Aviso: Aviso,
      CursoId: CursoId,
      AvisoOrigenId: AvisoOrigenId
    });
  }

  /***************** Functions *********************/
  public TipoDestino(id) { return this.TiposDestino.find(T => T.TipoDestinoId === id) }

}
