import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SesionService } from '../services/Sesion/Sesion.service';
import { LoggerService } from '../services/Utils/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenInterceptorService implements HttpInterceptor {

  constructor(
    private session: SesionService,
    private logger: LoggerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //this.logger.info('<----------------- Token Interceptor ----------------->');
    let request = req.clone();

    if ( this.session.isAuthenticated() && (!req.url.includes('SCORMApi') || req.url.includes('ActualizarCalificacionScormEstudianteAutogestivo'))) {
      const token: string = this.session.Token;
      if(!req.headers.has('Token')) {
        request = req.clone({
          headers: req.headers.append('Token',token)
        });
      }
    }

    return next.handle(request);
  }

}
