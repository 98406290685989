import { CuentaPersona, Cuenta } from "./Cuenta";

export abstract class PersonaBase {
  public Nombre: string;
  public ApellidoPaterno: string;
  public ApellidoMaterno: string;
  public CorreoAdicional: string;
  public EnSIASE: boolean;
  public _NombreCompleto?: string;
  constructor(P){
    if (P) {
      this.Nombre = P.Nombre;
      this.ApellidoPaterno = P.ApellidoPaterno;
      this.ApellidoMaterno = P.ApellidoMaterno;
      this.CorreoAdicional = P.CorreoAdicional;
      this.EnSIASE = P.EnSIASE;
      this.NombreCompleto('PMN');
    }
  }
  public NombreCompleto?(Orden?: string) {
    switch(Orden){
      case 'NPM': this._NombreCompleto = `${this.Nombre} ${this.ApellidoPaterno} ${this.ApellidoMaterno}`; break;
      case 'NP':  this._NombreCompleto = `${this.Nombre} ${this.ApellidoPaterno}`; break;
      case 'PMN': this._NombreCompleto = `${this.ApellidoPaterno} ${this.ApellidoMaterno} ${this.Nombre}`; break;
      case 'PN':  this._NombreCompleto = `${this.ApellidoPaterno} ${this.Nombre}`; break;
      default:    this._NombreCompleto = `${this.ApellidoPaterno} ${this.ApellidoMaterno} ${this.Nombre}`; break;
    }
    return this._NombreCompleto;
  }
}

export class Persona extends PersonaBase {
  PersonaId: number = 0;
  CURP: string;
  Estado: boolean = true;
  constructor(P) {
    super(P);
    if (P) {
      this.PersonaId = P.PersonaId;
      this.CURP = P.CURP;
      this.Estado = P.Estado;
    }
  }
}

export class PersonaCuentas extends Persona {
  Cuentas: CuentaPersona[] = [];
  constructor(P) {
    super(P);
    if (P) {
      this.Cuentas = P.Cuentas ? P.Cuentas.map(C => new CuentaPersona(C)) : [];
    }
  }
}

export class PersonaCuenta extends Cuenta implements PersonaBase {
  Nombre: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  CorreoAdicional: string;
  EnSIASE: boolean;
  _NombreCompleto: string;
  constructor(P){
    if (P) {
      super(P);
      this.Nombre = P.Nombre;
      this.ApellidoPaterno = P.ApellidoPaterno;
      this.ApellidoMaterno = P.ApellidoMaterno;
      this.CorreoAdicional = P.CorreoAdicional;
      this.EnSIASE = P.EnSIASE;
      this._NombreCompleto = this.NombreCompleto('PMN');
    }
  }
  public NombreCompleto(Orden?: string): string {
    let Name;
    switch(Orden) {
      case 'NPM': Name = `${this.Nombre} ${this.ApellidoPaterno} ${this.ApellidoMaterno}`; break;
      case 'NP':  Name = `${this.Nombre} ${this.ApellidoPaterno}`; break;
      case 'PMN': Name = `${this.ApellidoPaterno} ${this.ApellidoMaterno} ${this.Nombre}`; break;
      case 'PN':  Name = `${this.ApellidoPaterno} ${this.Nombre}`; break;
      default:    Name = `${this.ApellidoPaterno} ${this.ApellidoMaterno} ${this.Nombre}`; break;
    }
    this._NombreCompleto = Name;
    return Name;
  }
}
