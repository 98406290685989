import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { Rol } from 'src/app/models/Persona/Rol';
import { ApiHttpService } from '../api-http/ApiHttp.service';
import { SesionService } from '../Sesion/Sesion.service';

@Injectable({
  providedIn: 'root'
})
export class RolService {
  private domain = '';
  private _Roles: Rol[] = [
    new Rol({ RolId:1,  Estado: true,  Descripcion: "Administrador de Área Académica"}),
    new Rol({ RolId:2,  Estado: true,  Descripcion: "Profesor"}),
    new Rol({ RolId:3,  Estado: true,  Descripcion: "Profesor Asistente"}),
    new Rol({ RolId:4,  Estado: true,  Descripcion: "Observador"}),
    new Rol({ RolId:5,  Estado: true,  Descripcion: "Estudiante"}),
    new Rol({ RolId:6,  Estado: true,  Descripcion: "Administrador General"}),
    new Rol({ RolId:7,  Estado: false, Descripcion: "Asegurador de Calidad"}),
    new Rol({ RolId:8,  Estado: false, Descripcion: "AdministrativoM"}),
    new Rol({ RolId:9,  Estado: false, Descripcion: "Invitado"}),
    new Rol({ RolId:10, Estado: true,  Descripcion: "Asistente Administrador"}),
    new Rol({ RolId:11, Estado: true,  Descripcion: "Practicante de Administrador"}),
    new Rol({ RolId:12, Estado: true,  Descripcion: "Auditor"}),
    new Rol({ RolId:13, Estado: true,  Descripcion: "DENMS"}),
    new Rol({ RolId:14, Estado: true,  Descripcion: "Seguimiento Académico"}),
    new Rol({ RolId:15, Estado: false, Descripcion: "Administrador suplente"}),
    new Rol({ RolId:16, Estado: false, Descripcion: "Administrador suplente"}),
    new Rol({ RolId:17, Estado: true,  Descripcion: "Desempeño NEyM"}),
    new Rol({ RolId:18, Estado: true,  Descripcion: "Indicadores Central"}),
    new Rol({ RolId:19, Estado: true,  Descripcion: "Indicadores Auditoria"}),
    new Rol({ RolId:20, Estado: true,  Descripcion: "Indicadores Director Dependencia"}),
    new Rol({ RolId:21, Estado: true,  Descripcion: "Indicadores Coordinador EAD"}),
    new Rol({ RolId:22, Estado: true,  Descripcion: "Indicadores Licenciatura"}),
    new Rol({ RolId:23, Estado: true,  Descripcion: "Indicadores Preparatoria"}),
    new Rol({ RolId:24, Estado: true,  Descripcion: "Indicadores Posgrado"}),
  ];

  constructor(
    private SesionSVC: SesionService,
    private api: ApiHttpService,
    private http: HttpClient,
  ) { }

  set Roles(R) { this._Roles = R }
  get Roles() { return this._Roles }

  public getRol(id): Rol {
    return this._Roles.find(R => R.RolId === id);
  }
  public CatalogoRoles(RolId) {
    switch(RolId) {
      case 1: return this._Roles.filter(R =>R.RolId == 10 || ( R.RolId > 1 && R.RolId < 6));
      case 2: return this._Roles.filter(R =>R.RolId == 10 || ( R.RolId > 1 && R.RolId < 6));
      case 3: return this._Roles.filter(R =>R.RolId == 10 || ( R.RolId > 1 && R.RolId < 6));
      case 4: return this._Roles.filter(R =>R.RolId == 10 || ( R.RolId > 1 && R.RolId < 6));
      case 5: return this._Roles.filter(R =>R.RolId == 10 || ( R.RolId > 1 && R.RolId < 6));
      case 6: return this._Roles;
    }
  }

  public getCatalogoRoles(Rol: number[]) {
    return this._Roles.filter(R => Rol.includes(R.RolId));
  }
  public ObtenerRoles() {
    console.log(this.SesionSVC.Rol);
    return this._Roles;
  }

  public async ConsultarRoles() {
    console.log('Obtener Roles');
    const roles = await this.api.post('Rol','ConsultarRoles', {}).toPromise();
    console.log(roles);
    /*subscribe(res => {
      console.log(res);
      if(!res.Error) {
        this._Roles = res.Roles;
      }
    });*/
  }

  public isRolIndicadores() {
    console.log(this.SesionSVC.Rol);
    console.log(this.SesionSVC.Perfil.Rol);
    let RolesIndicaroes = [18,19,20,21,22,23]
    return RolesIndicaroes.includes(this.SesionSVC.Perfil.Rol.RolId);
  }

}
