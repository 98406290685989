import { Injectable } from '@angular/core';
import { ApiHttpService } from '../api-http/ApiHttp.service';
import { DateService } from '../Utils/Date/Date.service';

const TIPOS_DESTINO = [
  { TipoDestinoId: 1, Descripcion: "Organización" },
  { TipoDestinoId: 2, Descripcion: "Área Académica" },
  { TipoDestinoId: 3, Descripcion: "Unidad de Aprendizaje" },
]
const TIPOS_ESTATUS = [
  { EstadoEncuestaId: 1, Descripcion: "En Proceso" },
  { EstadoEncuestaId: 2, Descripcion: "Publicada" },
  { EstadoEncuestaId: 3, Descripcion: "Suspendida" },
  { EstadoEncuestaId: 4, Descripcion: "Terminada" },
]
const TIPOS_REACTIVO = [
  { TipoReactivoId: 1, Nombre: "Verdadero/Falso" },
  { TipoReactivoId: 2, Nombre: "Opción Múltiple" },
  { TipoReactivoId: 3, Nombre: "Respuesta Múltiple" },
  { TipoReactivoId: 4, Nombre: "Relacionar" },
  { TipoReactivoId: 5, Nombre: "Texto Abierto" },
]

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {
  private _Encuesta;
  private _TiposEstatus = TIPOS_ESTATUS;
  private _TiposDestino = TIPOS_DESTINO;
  private _TiposReactivo = TIPOS_REACTIVO;

  public Dominio = 'Encuesta';

  constructor(
    private API: ApiHttpService,
    private FechaSVC: DateService
  ) { }

  set Encuesta(E) { this._Encuesta = E }
  get Encuesta() { return this._Encuesta }

  get TiposDestino() { return this._TiposDestino }
  get TiposEstatus() { return this._TiposEstatus }
  get TiposReactivo() { return this._TiposReactivo }

  /****************** WebApi Services ****************/

  public ConsultarEncuestas(CursoId){
    return this.API.POST(this.Dominio, 'ConsultarEncuestas', {
      CursoId: CursoId
    });
  }
  public ConsultarEncuesta(_EncuestaId){
    return this.API.POST(this.Dominio,'/ConsultarEncuesta', {
      EncuestaId: _EncuestaId
    });
  }
  public ConsultarEncuestasAdministradorAreaAcademicaCurso(_CursoId) {
    return this.API.POST(this.Dominio,'ConsultarEncuestasAdministradorAreaAcademicaCurso', {
      CursoId: _CursoId
    });
  }
  public ConsultarEncuestasAsignadas(_CursoId) {
    return this.API.POST(this.Dominio,'ConsultarEncuestasAsignadas', {
      CursoId: _CursoId
    });
  }
  public ConsultarEncuestaResultado(EncuestaId){
    return this.API.POST(this.Dominio,"ConsultarEncuestaResultado", {
      EncuestaId: EncuestaId
    });
  }
  public ConsultarRespuestasAbiertas(_EncuestaReactivoId) {
    return this.API.POST(this.Dominio,"ConsultarRespuestasAbiertas", {
      EncuestaReactivoId: _EncuestaReactivoId
    });
  }
  public ConsultarResponderEncuesta(EncuestaId){
    return this.API.POST(this.Dominio,"ConsultarResponderEncuesta", {
      EncuestaId: EncuestaId
    });
  }
  public ActualizarEncuesta(Encuesta){
    return this.API.POST(this.Dominio,"ActualizarEncuesta", {
      Encuesta: Encuesta
    });
  }
  public CopiarEncuesta(_CursoId, _Encuesta, _EncuestaOrigenId){
    return this.API.POST(this.Dominio,"CopiarEncuesta", {
      CursoId: _CursoId,
      Encuesta: _Encuesta,
      EncuestaOrigenId: _EncuestaOrigenId
    });
  }
  public ReiniciarEncuesta(_EncuestaId) {
    return this.API.POST(this.Dominio,'ReiniciarEncuesta', {
      EncuestaId: _EncuestaId
    });
  }

  public ConsultarEncuestaReactivos(EncuestaId){
    return this.API.POST(this.Dominio,"ConsultarEncuestaReactivos", {
      EncuestaId: EncuestaId
    });
  }
  public ActualizarEncuestaReactivos(Reactivos){
    return this.API.POST(this.Dominio,"ActualizarEncuestaReactivos", {
      EncuestaReactivos: Reactivos
    });
  }
  public ActualizarEjecucionEncuesta(EncuestaId, Reactivos){
    return this.API.POST(this.Dominio,"ActualizarEjecucionEncuesta", {
      EncuestaId: EncuestaId,
      Reactivos: Reactivos
    });
  }
  public ActualizarEjecucionCuenta(data){
    return this.API.POST(this.Dominio,"ActualizarEjecucionCuenta", data);
  }


  /****************** Other Functions ****************/
  public EncuestaEstatusTiempo(FechaInicio, FechaFin) {
    if(this.FechaSVC.DatesDiffSeconds(FechaInicio,new Date()) < 0) {
      return 1;// Aun no comienza
    } else if(this.FechaSVC.DatesDiffSeconds(FechaFin,new Date()) < 0) {
      return 2;// Esta en tiempo de ejecucion
    } else {
      return 3// Termino el tiempo de la encuesta
    }
  }
  public getNextPosicionReactivo(Encuesta?){
    Encuesta = Encuesta ? Encuesta : this.Encuesta;
    return (Encuesta.Reactivos.length + 1);
  }
  public getTipoDestino(id){
    return this._TiposDestino.find(TD => TD.TipoDestinoId == id);
  }
  public getEstatus(id){
    return this._TiposEstatus.find(E => E.EstadoEncuestaId == id);
  }
  public getTipoReactivo(TipoReactivoId){
    return this._TiposReactivo.filter(TR => TR.TipoReactivoId == TipoReactivoId)[0];
  }


}
