import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export enum LogLevel {
  NONE  = 0,
  INFO  = 1,
  WARN  = 2,
  ERROR = 3
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private status = !environment.production;
  private logLevel: any;

  constructor() {

  }

  info(message: string) {
    this.logWith(LogLevel.INFO, message);
  }
  warn(message: string) {
    this.logWith(LogLevel.WARN, message);
  }
  error(message: string) {
    this.logWith(LogLevel.ERROR, message);
  }
  data(data: any, message?: string) {
    this.logWith(LogLevel.NONE, message, data);
  }

  private logWith(level: any, msg: string, data?: any): void {
    if (this.status && level <= LogLevel.ERROR) {

      switch (level) {

        case LogLevel.NONE:
          return console.log(msg,data);

        case LogLevel.INFO:
          return console.info('%c' + msg, 'color: #6495ED');

        case LogLevel.WARN:
          return console.warn('%c' + msg, 'color: #FF8C00');

        case LogLevel.ERROR:
          return console.error('%c' + msg, 'color: #DC143C');

        default:
          console.debug(msg);
      }
    }
  }

}
