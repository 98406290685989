import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { request } from 'http';
import { SesionService } from '../services/Sesion/Sesion.service';
import { LoggerService } from '../services/Utils/logger.service';

@Injectable({
  providedIn: 'root'
})
export class SistemaInterceptorService implements HttpInterceptor {

  constructor(
    private sesion: SesionService,
    private logger: LoggerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //this.logger.info('<----------------- Sistema Interceptor ----------------->');
    let _headers = req.headers;
    console.info('REQUEST:',req);
    if(!req.url.includes('SCORMApi') || req.url.includes('ActualizarCalificacionScormEstudianteAutogestivo')) {
      if(!req.headers.has('SistemaId')) _headers = _headers.append('SistemaId','1');
      if(this.sesion.isAuthenticated() && this.sesion.Perfil) {
        if(!req.headers.has('AreaAcademicaId')) _headers = _headers.append('AreaAcademicaId',this.sesion.Perfil.AreaAcademica.AreaAcademicaId.toString());
        if(!req.headers.has('RolId')) _headers = _headers.append('RolId',this.sesion.Rol.RolId.toString());
      }
    }
    const _req = req.clone({
      headers: _headers
    });

    return next.handle(_req);
  }

}
