import { Rol, RolBase } from "./Rol";
import { AreaAcademica } from "../AreaAcademica/AreaAcademica";

export interface CuentaBase {
  CuentaId: number;
  NombreUsuario: string;
  EnSIASE: boolean;
  CorreoUniversitario: string;
}

export interface CuentaCursoBase {
  CuentaId: number;
  Estado: boolean;
}

export class Cuenta implements CuentaBase{
  CuentaId: number = 0;
  NombreUsuario: string;
  EnSIASE: boolean;
  CorreoUniversitario: string;
  Estado: boolean = true;
  AreaAcademica?: any;

  constructor(C) {
    if(C){
      this.NombreUsuario = C.NombreUsuario;
      this.EnSIASE = C.EnSIASE;
      this.CuentaId = C.CuentaId;
      this.CorreoUniversitario = C.CorreoUniversitario;
      this.Estado = C.Estado;
    }
  }
}


export class CuentaCurso {
  CuentaId: number;
  Estado: boolean;
  constructor(CuentaId: number, Estado: boolean) {
    this.CuentaId = CuentaId;
    this.Estado = Estado;
  }
}

export class CuentaPersona extends Cuenta {
  AreasAcademicas: CuentaAreaAcademica[] = [];
  OrganizacionId: number;
  constructor(C) {
    super(C);
    if(C) {
      this.AreasAcademicas = C.AreasAcademicas ? C.AreasAcademicas.map(AA => new CuentaAreaAcademica(AA)) : [];
      this.OrganizacionId = C.OrganizacionId;
    }
  }
}

export class CuentaAreaAcademica {
  CuentaAreaAcademicaId: number = 0;
  AreaAcademica: AreaAcademica;
  AreaAcademicaId: number;
  Descripcion: string;
  EsBloqueado: boolean;
  Estado: boolean;
  Roles: CuentaAreaAcademicaRol[] = [];

  constructor(AA){
    if (AA) {
      this.CuentaAreaAcademicaId = AA.CuentaAreaAcademicaId;
      this.AreaAcademica = AA.AreaAcademica ? new AreaAcademica(AA.AreaAcademica) : null;
      this.AreaAcademicaId = AA.AreaAcademica ? AA.AreaAcademica.AreaAcademicaId : AA.AreaAcademicaId;
      this.Descripcion = AA.AreaAcademica ? AA.AreaAcademica.Nombre : AA.Descripcion;
      this.EsBloqueado = AA.EsBloqueado;
      this.Estado = AA.Estado;
      this.Roles = AA.Roles.map(R => new CuentaAreaAcademicaRol(R,AA.CuentaAreaAcademicaId));
    }
  }
}

export class CuentaAreaAcademicaRol extends RolBase {
  AreaAcademicaId: number;
  EsRolPrincipal: boolean;
  Estado: boolean;
  constructor(R, AreaAcademicaId?) {
    super(R);
    if (R) {
      this.AreaAcademicaId = AreaAcademicaId;
      this.EsRolPrincipal = R.EsRolPrincipal;
      this.Estado = R.Estado;
    }
  }
}
