import { HighlightTextPipe } from './../../pipes/highlight-text.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafeHTMLPipe } from 'src/app/pipes/safe-html.pipe';
import { SafeURLPipe } from 'src/app/pipes/safe-url';
import { UniquePipe } from 'src/app/pipes/unique.pipe';
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { CryptoPipe } from 'src/app/pipes/crypto.pipe';



@NgModule({
  imports: [
    CommonModule,
    NgPipesModule,
    OrderModule,
    FilterPipeModule,
  ],
  declarations: [
    SafeHTMLPipe,
    SafeURLPipe,
    UniquePipe,
    HighlightTextPipe,
    CryptoPipe
  ],
  exports: [
    SafeHTMLPipe,
    SafeURLPipe,
    UniquePipe,
    HighlightTextPipe,
    NgPipesModule,
    OrderModule,
    FilterPipeModule,
    CryptoPipe
  ]
})
export class PipesModule { }
