import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
//import { DesempenoModule } from './modules/rol/desempeno/desempeno.module';


const routes: Routes = [
    //{ path: '', redirectTo: 'Login', pathMatch:'full'},
    //{ path: '**', component: PageNotFoundComponent },
    //{ path: 'Pruebas', component: PruebasComponent },
    {
        path: '',
        data: { breadcrumb: '' },
        loadChildren: () => import('./modules/general/general.module').then(M => M.GeneralModule)
    },
    {
      path: 'App',
      data: { breadcrumb: '' },
      loadChildren: () => import('./modules/AppMain/AppMain.module').then(M => M.AppMainModule)
    },
    {
        path: 'Pruebas',
        loadChildren: () => import('./modules/pruebas/pruebas.module').then(M => M.PruebasModule)
    },
    {
        path: 'AdminGral',
        loadChildren: () => import('./modules/rol/admin-general/admin-general.module').then(M => M.AdminGeneralModule)
    },
    {
        path: 'Auditor',
        loadChildren: () => import('./modules/rol/auditor/auditor.module').then(M => M.AuditorModule)
    },
    {
        path: 'Desempeno',
        loadChildren: () => import('./modules/rol/desempeno/desempeno.module').then(M => M.DesempenoModule)
    },
    {
        path: 'Seguimiento',
        loadChildren: () => import('./modules/rol/denms/denms.module').then(M => M.DenmsModule)
    },
    {
      path: 'Indicadores',
      loadChildren: () => import('./modules/indicadores/indicadores.module').then(M => M.IndicadoresModule)
    },

    {
        path: 'Profesor',
        loadChildren: () => import('./modules/rol/profesor/profesor.module').then(M => M.ProfesorModule)
    },
    {
        path: 'Estudiante',
        loadChildren: () => import('./modules/rol/estudiante/estudiante.module').then(M => M.EstudianteModule)
    },
    {
      path: 'Examenes',
      loadChildren: () => import('src/app/modules/nx-examen/nx-examen.module').then(m => m.NxExamenModule)
    }
];

@NgModule({
    imports: [
      RouterModule.forRoot(routes ,{
        useHash: true,
        onSameUrlNavigation: 'reload'
      })
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
