import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyMaterialModule } from '../material.module';
import { SharedWidgetModule } from '../shared/widgets.module';
import { PipesModule } from 'src/app/modules/shared/Pipes.module';
import { LoadingComponentsModule } from '../shared/LoadingComponents.module';
import { EncuestaResponderComponent } from 'src/app/components/encuesta/encuesta-responder/encuesta-responder.component';
import { EncuestaEjecucionComponent } from 'src/app/components/encuesta/encuesta-ejecucion/encuesta-ejecucion.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MyMaterialModule,
    SharedWidgetModule,
    PipesModule,
    LoadingComponentsModule
  ],
  declarations: [
    EncuestaResponderComponent,
    EncuestaEjecucionComponent
  ],
  exports: [
    EncuestaResponderComponent,
    EncuestaEjecucionComponent
  ],
  entryComponents: [
    EncuestaResponderComponent
  ]
})
export class EncuestasSharedModule { }
