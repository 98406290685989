import { EncuestaReactivoRespuesta, EncuestaReactivoRespuestaVerdaderoFalso } from "./EncuestaReactivoRespuesta";

export abstract class EncuestaReactivoBase {
  EncuestaId: number = 0;
  EncuestaReactivoId: number;
  Descripcion: string;
  Posicion: number;
  TipoReactivoId: number;
  Estado: boolean = true;

  constructor(R) {
    if(R){
      this.EncuestaReactivoId = R.EncuestaReactivoId;
      this.EncuestaId = R.EncuestaId;
      this.Descripcion = R.Descripcion;
      this.TipoReactivoId = R.TipoReactivoId;
      this.Posicion = R.Posicion;
      this.Estado = R.Estado;
    }
  }
}

export class EncuestaReactivo extends EncuestaReactivoBase {
  CantidadRespuestas: number;
  Respuestas: EncuestaReactivoRespuesta[] = [];
  RespuestaVerdaderoFalso: EncuestaReactivoRespuestaVerdaderoFalso;

  constructor(RE){
    super(RE);
    if (RE) {
      this.CantidadRespuestas = RE.CantidadRespuestas;
      this.Respuestas = RE.Respuestas ? RE.Respuestas.map(R => new EncuestaReactivoRespuesta(R)) : null;
      this.RespuestaVerdaderoFalso = RE.RespuestaVerdaderoFalso ? new EncuestaReactivoRespuestaVerdaderoFalso(RE.RespuestaVerdaderoFalso) : null;
    }
  }
}
