import { EncuestaDestino } from "./EncuestaDestino";
import { EncuestaRol } from "./EncuestaRol";
import { EncuestaReactivo } from "./EncuestaReactivo";
import { EncuestaReactivoEjecucion } from "./EncuestaReactivoEjecucion";

export class EncuestaBase {
  EncuestaId: number;
  Titulo: string;
  Descripcion: string;
  FechaInicio: any;
  FechaFin: any;
  Ejecuciones: number;
  TipoDestinoId: number;
  EstadoEncuestaId: number;
  Estado: boolean;

  constructor(E) {
    if(E) {
      this.EncuestaId = E.EncuestaId;
      this.Titulo = E.Titulo;
      this.Descripcion = E.Descripcion;
      this.FechaInicio = new Date(E.FechaInicio);
      this.FechaFin = new Date(E.FechaFin);
      this.TipoDestinoId = E.TipoDestinoId;
      this.EstadoEncuestaId = E.EstadoEncuestaId;
      this.Estado = E.Estado;
    }
  }
}

export class Encuesta extends EncuestaBase {
  Ejecuciones: number;
  RolId: number;
  Propia: boolean;
  BloquearCurso: boolean;
  Destinos?: EncuestaDestino[] = [];
  RolesDirigido?: EncuestaRol[] = [];

  constructor(E) {
    super(E);
    if (E) {
      this.Ejecuciones = E.Ejecuciones;

      this.RolId = E.RolId;
      this.Propia = E.Propia;
      this.BloquearCurso = E.BloquearCurso;
      this.Destinos = E.Destinos ? E.Destinos : [];
      this.RolesDirigido = E.RolesDirigido ? E.RolesDirigido : [];
    }
  }
}

export class EncuestaReactivos extends Encuesta {
  Reactivos: EncuestaReactivo[] = [];
  constructor(E) {
    super(E);
    this.Reactivos = E.Reactivos ? E.Reactivos.map(R => new EncuestaReactivo(R)) : [];
  }
}
export class EncuestaResponder extends Encuesta {
  Reactivos: EncuestaReactivoEjecucion[] = [];
  constructor(E) {
    super(E);
    this.Reactivos = E.Reactivos ? E.Reactivos.map(R => new EncuestaReactivoEjecucion(R)) : [];
  }
}

export class EncuestaResultados extends Encuesta {
  Reactivos: EncuestaReactivoEjecucion[] = [];
  constructor(E) {
    super(E);
    this.Reactivos = E.Reactivos ? E.Reactivos.map(R => new EncuestaReactivoEjecucion(R)) : [];
  }
}
