import { Rol } from "./DTO/Rol";

//import * as CONFIG from 'src/assets/js/config';
/*declare var CONFIG: any;

let URL = CONFIG.get_URL();
let URL_API = CONFIG.get_URL_API();*/

export class CONSTANTS {
    public static URL = "";
    public static URL_SSL = "";
    public static URL_SCORM = "";
    public static API_URL_SCORM = "";

    public static IP = "";
    public static API_ENDPOINT = "";
    public static API_LTI = "";
    public static API_OFFICE = "";
    public static API_BITACORA = "";

    public static Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    public static AlphabetOrden = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    public static URLVideotutoriales = 'https://www.youtube.com/embed/';

    public static Textos = {
        Error: {
            General: "Ocurrió un problema en la ejecución la operación"
        }
    }


    /* Mensajes de Texto */
    public static MSG = {
        input: {
            required: "Este es un campo requerido",
            pettern: {
                text: "Campo de texto",
                curp: "Formato de CURP inválido",
                email: "Formato de email inválido",
            },
            minlength: {
                getText(n): string {return "Minimo "+n+" caracteres"}
            }
        },
        carpeta: {
            empty: {
                NoAsignados: "No cuentas con Unidades de Aprendizaje asignadas",
                SinCursos: "La carpeta seleccionada no cuenta con Unidades de Aprendizaje",
                SinResultados: "No se encontraron Unidades de Aprendizaje que coincidan con la búsqueda"
            }
        },
        DragDrop: {
            Texto: "<p>Arrastra tu archivo en esta área</p><p>ó</p><p>Da clic para seleccionar desde tu PC</p>",
            ExtensionsImage: "(.png, .jpg)",
            ExtensionsFile: "(.pdf)"
        }
    }

    public static EXTENSIONS = {
        IMAGE:  ["PNG, JPG"],
        FILE:   ["PDF"]
    }
    public static ExtensionesPermitidas = {
        Dcoumento: [
            {Ext:"PDF", Descripcion: "PDF"},
            {Ext:"DOC", Descripcion: "MS Office Word"},
            {Ext:"PPT", Descripcion: "MS Office Power Point"},
            {Ext:"XLS", Descripcion: "MS Office Excel"},
            {Ext:"TXT", Descripcion: "Archivo de Texto Plano"},
            {Ext:"CSV", Descripcion: "Archivo Separado por Comas"},
        ],
        Imagen: [
            {Ext:"JPG",  Descripcion: "Imagen"},
            {Ext:"JPEG", Descripcion: "Imagen"},
            {Ext:"PNG",  Descripcion: "Imagen"},
            {Ext:"GIF",  Descripcion: "Imagen"},
        ],
    }

    public static COLORS = {
        RUBRICA : [
            '#02bd00', //Verde
            '#fdff00', //Amarillo
            '#ff1800' //Rojo
        ],
        CURSOS : [
            '#02bd00', //Verde
            '#ff1800' //Rojo
        ]
    }

    /* Calendar Colors */
    public static CALENDAR_COLORS = {
        red:    { primary: '#ad2121', secondary: '#FAE3E3' },
        blue:   { primary: '#1e90ff', secondary: '#D1E8FF' },
        yellow: { primary: '#e3bc08', secondary: '#FDF1BA' }
    };

    /* Meses */
    public static MESES = [
        {Id: 1, Valor: "01", Nombre:"Enero"},
        {Id: 2, Valor: "02", Nombre:"Febrero"},
        {Id: 3, Valor: "03", Nombre:"Marzo"},
        {Id: 4, Valor: "04", Nombre:"Abril"},
        {Id: 5, Valor: "05", Nombre:"Mayo"},
        {Id: 6, Valor: "06", Nombre:"Junio"},
        {Id: 7, Valor: "07", Nombre:"Julio"},
        {Id: 8, Valor: "08", Nombre:"Agosto"},
        {Id: 9, Valor: "09", Nombre:"Septiembre"},
        {Id: 10,Valor: "10", Nombre:"Octubre"},
        {Id: 11,Valor: "11", Nombre:"Noviembre"},
        {Id: 12,Valor: "12", Nombre:"Diciembre"},
    ];

    /*Mensajes Request SnackBar */
    public static MSG_REQUEST = {
        success: {
            carpeta: {
                add: {getText(curso): string {return 'Se ha creado la carpeta '+curso+' con éxito'}},
                update: {getText(curso): string {return 'Se ha actualizado la carpeta '+curso+' con éxito'}},
                delete: {getText(curso): string {return 'Se ha eliminado la carpeta '+curso+' con éxito'}},
            },
            curso: {
                add: {getText(curso): string {return 'Se ha creado la carpeta '+curso+' con éxito'}},
                update: {getText(curso): string {return 'Se ha actualizado la carpeta '+curso+' con éxito'}},
                delete: {getText(curso): string {return 'Se ha eliminado la carpeta '+curso+' con éxito'}},
            },
            grupo: {
                add: {getText(g): string {return 'Se ha creado el "Grupo '+g+' con éxito'}},
                update: {getText(g1,g2): string {return 'Se ha actualizado el nombre del "Grupo '+g1+'" a "Grupo '+g2+'"'}},
                delete: {getText(g): string {return 'Se ha eliminado "Grupo '+g+'" con éxito'}},
                addStudent: {getText(g): string {return 'Inscripción de Esutdiantes del "Grupo '+g+'" realizada con éxito'}},
                removeStudent: {getText(g): string {return 'Desinscripción de Estudiantes del "Grupo '+g+'" realizada con éxito'}},
                general: {getText(g): string {return 'Se han actualizado los Grupos con éxito'}},
            },
        },
        error: {
            general: 'Ocurró un problema, porfavor intentalo de nuevo',
        }
    }

    /* ROL */
    public static ROLES = [
        new Rol({ RolId:1,  Estado: true,  Descripcion: "Administrador de Área Académica"}),
        new Rol({ RolId:2,  Estado: true,  Descripcion: "Profesor"}),
        new Rol({ RolId:3,  Estado: true,  Descripcion: "Profesor Asistente"}),
        new Rol({ RolId:4,  Estado: true,  Descripcion: "Observador"}),
        new Rol({ RolId:5,  Estado: true,  Descripcion: "Estudiante"}),
        new Rol({ RolId:6,  Estado: true,  Descripcion: "Administrador General"}),
        new Rol({ RolId:7,  Estado: false, Descripcion: "Asegurador de Calidad"}),
        new Rol({ RolId:8,  Estado: false, Descripcion: "AdministrativoM"}),
        new Rol({ RolId:9,  Estado: false, Descripcion: "Invitado"}),
        new Rol({ RolId:10, Estado: true,  Descripcion: "Asistente Administrador"}),
        new Rol({ RolId:11, Estado: true,  Descripcion: "Practicante de Administrador"}),
        new Rol({ RolId:12, Estado: true,  Descripcion: "Auditor"}),
        new Rol({ RolId:13, Estado: true,  Descripcion: "DENMS"}),
        new Rol({ RolId:14, Estado: true,  Descripcion: "Seguimiento Académico"}),
        new Rol({ RolId:15, Estado: false, Descripcion: "Administrador suplente"}),
        new Rol({ RolId:16, Estado: false, Descripcion: "Administrador suplente"}),
        new Rol({ RolId:17, Estado: true,  Descripcion: "Dsempeño NEyM"}),
        new Rol({ RolId:18, Estado: true,  Descripcion: "Indicadores Central"}),
        new Rol({ RolId:19, Estado: true,  Descripcion: "Indicadores Auditoria"}),
        new Rol({ RolId:20, Estado: true,  Descripcion: "Indicadores Director Dependencia"}),
        new Rol({ RolId:21, Estado: true,  Descripcion: "Indicadores Coordinador EAD"}),
        new Rol({ RolId:22, Estado: true,  Descripcion: "Indicadores Licenciatura"}),
        new Rol({ RolId:23, Estado: true,  Descripcion: "Indicadores Preparatoria"}),
        new Rol({ RolId:24, Estado: true,  Descripcion: "Indicadores Posgrado"})
    ];

    /* MODELO */
    public static MODELOS = [
        { ModeloId: 1, Descripcion: "Objetivos"},
        { ModeloId: 2, Descripcion: "Competencias"}
    ];

    /* MODALIDAD */
    public static MODALIDADES = [
        { ModalidadId: 1, Descripcion: "ESCOLARIZADA", Abreviatura: "ESC", Estado: true },
        { ModalidadId: 2, Descripcion: "NO ESCOLARIZADA", Abreviatura: "NO ESC", Estado: true },
        { ModalidadId: 3, Descripcion: "ABIERTO", Abreviatura: "AB", Estado: true },
        { ModalidadId: 4, Descripcion: "MIXTA", Abreviatura: "MI", Estado: true },
        { ModalidadId: 5, Descripcion: "MIXTA EN LINEA", Abreviatura: "MI LI", Estado: true },
        { ModalidadId: 6, Descripcion: "MIXTA A DISTANCIA", Abreviatura: "MI DI", Estado: true },
        { ModalidadId: 7, Descripcion: "MIXTA ABIERTO", Abreviatura: "MI AB", Estado: true }
    ];

    /* SIASE */
    public static  tipo_curso = [
        { valor: true, nombre: "SIASE"},
        { valor: false, nombre: "NEXUS"},
    ]

    /* Estructura */
    public static TIPO_ESTRUCTURA = [
        { TipoEstructuraId: 1, ModeloId:2, Nombre: "Etapa"},
        { TipoEstructuraId: 2, ModeloId:2, Nombre: "Capítulo"},
        { TipoEstructuraId: 3, ModeloId:2, Nombre: "Fase"},
        { TipoEstructuraId: 4, ModeloId:1, Nombre: "Unidad"},
        { TipoEstructuraId: 5, ModeloId:1, Nombre: "Módulo"},
    ]

    /* Tipo Evidencia */
    public static TIPO_EVIDENCIA = [
        { TipoEvidenciaId: 1, Nombre: "Evidencia de Aprendizaje"},
        { TipoEvidenciaId: 2, Nombre: "Elemento Complementario"},
        { TipoEvidenciaId: 3, Nombre: "Producto Integrador"},
        { TipoEvidenciaId: 4, Nombre: "Actividad de Aprendizaje"},
    ];
    /* Tipo Elemento Evaluable */
    public static TIPO_ELEMENTO_EVALUABLE = [
        { TipoElementoId: 1, Nombre:"Evidencia", Descripcion:"Evidencia de Aprendizaje"},
        { TipoElementoId: 2, Nombre:"PIA", Descripcion: "Producto Integrador de Aprendizaje"},
        { TipoElementoId: 3, Nombre:"Actividad de Aprendizaje", Descripcion: "Actividad"},
        { TipoElementoId: 4, Nombre:"Examen", Descripcion: "Examen"},
        { TipoElementoId: 5, Nombre:"Foro", Descripcion: "Foro"},
        { TipoElementoId: 6, Nombre:"Concepto Calificacion", Descripcion: "Concepto Calificacion"}
    ];

    /* Tipo Coevaluacion */
    public static TIPO_COEVALUACION = [
        { TipoCoevaluacionId: 1, Nombre: "Por Curso"},
        { TipoCoevaluacionId: 2, Nombre: "Por Equipo"},
    ];

    /* Tipo Dcoumento */
    public static TIPO_DOCUMENTO = [
        { TipoDocumentoId: 1, Nombre: "Programa Analitico" },
        { TipoDocumentoId: 2, Nombre: "Mapa Conceptual" },
        { TipoDocumentoId: 3, Nombre: "Representacion Grafica" },
        { TipoDocumentoId: 4, Nombre: "Recurso" },

    ];

    /* Tipo Destino Aviso */
    public static TIPO_AVISO_DESTINO = [
        { TipoDestinoId: 1, Nombre: "Área Académica" },
        { TipoDestinoId: 2, Nombre: "Unidad de Aprendizaje" },
        /*{ TipoDestinoId: 3, Nombre: "Cuenta" }*/

    ];
    /* Encuesta Tipo Destino */
    public static ENCUESTA_TIPO_DESTINO = [
        { TipoDestinoId: 1, Descripcion: "Organización" },
        { TipoDestinoId: 2, Descripcion: "Área Académica" },
        { TipoDestinoId: 3, Descripcion: "Unidad de Aprendizaje" },
    ];
    /* Encuesta Estado */
    public static ENCUESTA_ESTATUS = [
        { EstadoEncuestaId: 1, Descripcion: "En Proceso" },
        { EstadoEncuestaId: 2, Descripcion: "Publicada" },
        { EstadoEncuestaId: 3, Descripcion: "Suspendida" },
        { EstadoEncuestaId: 4, Descripcion: "Terminada" },
    ];

    /* Examen Tipo Tiempo */
    public static EXAMEN_TIPO_TIEMPO = [
        { TipoTiempoExamenId: 3, Nombre: "Sin Límite" },
        { TipoTiempoExamenId: 1, Nombre: "General" },
        { TipoTiempoExamenId: 2, Nombre: "Por Reactivo" },
        //{ EstadoEncuestaId: 3, Descripcion: "PorBloque" },
    ];

    /* Examen Estado */
    public static EXAMEN_ESTADO = [
        { EstadoExamenId: 1, Nombre: "Incompleto" },
        { EstadoExamenId: 2, Nombre: "Completo" },
        { EstadoExamenId: 3, Nombre: "Publicado" },
        { EstadoExamenId: 4, Nombre: "Suspendido" },
        { EstadoExamenId: 5, Nombre: "Terminado" },
    ];
    /* Examen Estado */
    public static EXAMEN_TERMINACION = [
        { EstadoId: 0, Nombre: "Sin Terminar",   Text: "Pendiente" },
        { EstadoId: 1, Nombre: "Por Tiempo",     Text: "" },
        { EstadoId: 2, Nombre: "Por Estudiante", Text: "" },
        { EstadoId: 3, Nombre: "Por Profesor",   Text: "" },

    ];

    /* Examen Tipo Retroalimentación */
    public static EXAMEN_TIPO_RETROALIMENTACION = [
        { TipoRetroalimentacionAlumnoId: 1, Nombre: "Su respuesta, su calificación y la respuesta correcta" },
        { TipoRetroalimentacionAlumnoId: 2, Nombre: "Sólo su respuesta y su calificación" },
        { TipoRetroalimentacionAlumnoId: 3, Nombre: "Sólo su calificación" }
    ];

    /* Estado */
    public static CURSO_IMAGENES = [
        {CursoId: null,   ImagenId:1,  Image: "./assets/img/cursos/default.jpg"},
        {CursoId: 214230, ImagenId:2,  Image: "https://cdn.kastatic.org/googleusercontent/pmSFOg8sp5pAK3K1oP7KAB3bckl1uYej2PfZtGQI3LDQ4jsLzkMhDNfEA6bxiUUGFt2aQUgwwKhPINWpzy5F7sD0"},
        {CursoId: 224242, ImagenId:3,  Image: "https://image.slidesharecdn.com/electricidadymagnetismo1-130709192314-phpapp01/95/electricidad-y-magnetismo-1-1-638.jpg?cb=1373397855"},
        {CursoId: 214231, ImagenId:4,  Image: "https://www.definicionabc.com/wp-content/uploads/estadistica.jpg"},
        {CursoId: 214232, ImagenId:5,  Image: "http://www.die.uma.es/archivos/electromagnetismo2.jpg"},
        {CursoId: 214233, ImagenId:6,  Image: "https://cdn.pruebat.org/recursos/recursos/matematicas.png"},
        {CursoId: 274286, ImagenId:7,  Image: "./assets/img/cursos/274286.jpg"},
        {CursoId: 274347, ImagenId:8,  Image: "./assets/img/cursos/274347.jpg"},
        {CursoId: 274358, ImagenId:9,  Image: "./assets/img/cursos/274358.jpg"},
        {CursoId: 274388, ImagenId:10, Image: "./assets/img/cursos/274388.jpg"},
        {CursoId: 274389, ImagenId:11, Image: "./assets/img/cursos/274389.jpg"},
        {CursoId: 274396, ImagenId:12, Image: "./assets/img/cursos/274396.jpg"},
    ];

    /* Estado */
    public static CURSO_ESTADOS = [
        {valor: true, nombre: "Disponible"},
        {valor: false, nombre: "No Disponible"},
    ];

    /* Tipos de Reactivo */
    public static TiposReactivo = [
      { TipoReactivoId: 1, Nombre: "Verdadero/Falso" },
      { TipoReactivoId: 2, Nombre: "Opción Múltiple" },
      { TipoReactivoId: 3, Nombre: "Respuesta Múltiple" },
      { TipoReactivoId: 4, Nombre: "Relacionar" },
      { TipoReactivoId: 5, Nombre: "Texto Abierto" },
    ];

    /* Reactivo Tipo Verdadero-Falso  */
    public static TiposReactivoVerdaderoFalso = [
        { TipoRespuestaVerdaderoFalsoId: 1, Descripcion:"Verdadero - Falso", NombreVerdadero: "Verdadero", NombreFalso: "Falso" },
        //{ TipoRespuestaVerdaderoFalsoId: 2, Descripcion:"V - F", NombreVerdadero: "V", NombreFalso: "F" },
        //{ TipoRespuestaVerdaderoFalsoId: 3, Descripcion:"SI - NO", NombreVerdadero: "SI", NombreFalso: "NO" },
    ];

    /* Rubrica */
    public static RUBRICA_TIPOS_SABER = [
        { TipoSaberId: 3, Nombre: "Actitudes / Valores" },
        { TipoSaberId: 1, Nombre: "Conocimientos" },
        { TipoSaberId: 2, Nombre: "Habilidades" }
    ];



    /*
    public static moment_config = {
        months : 'Enero_Febrero_Marzo_Abril_Mayo_Junio__août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
            sameDay : '[Aujourd’hui à] LT',
            nextDay : '[Demain à] LT',
            nextWeek : 'dddd [à] LT',
            lastDay : '[Hier à] LT',
            lastWeek : 'dddd [dernier à] LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : 'dans %s',
            past : 'il y a %s',
            s : 'quelques secondes',
            m : 'une minute',
            mm : '%d minutes',
            h : 'une heure',
            hh : '%d heures',
            d : 'un jour',
            dd : '%d jours',
            M : 'un mois',
            MM : '%d mois',
            y : 'un an',
            yy : '%d ans'
        },
        dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
        ordinal : function (number) {
            return number + (number === 1 ? 'er' : 'e');
        },
        meridiemParse : /PD|MD/,
        isPM : function (input) {
            return input.charAt(0) === 'M';
        }
    }
    */
   public static TiposPeriodo = [
       {Tipo: 1, Descripcion: "Semestral", Descripcion2: "Semestre"},
       {Tipo: 2, Descripcion: "Tetramestral",  Descripcion2: "Tetramestre"}
   ]
   public static TipoCompetencia = [
      { Tipo: 1, Descripcion: "Disciplinar" },
      { Tipo: 2, Descripcion: "Genérica" },
      { Tipo: 3, Descripcion: "General" },
      { Tipo: 4, Descripcion: "Profesional" }
   ];

   public static TiemposMargenSeguimientoDocente = [ 24, 48, 72];
}
