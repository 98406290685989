import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EncuestaResponder, Encuesta } from 'src/app/models/Encuesta/Encuesta';
import { EncuestaService } from 'src/app/services/Encuesta/Encuesta.service';
import { SesionService } from 'src/app/services/Sesion/Sesion.service';

@Component({
  selector: 'app-encuesta-ejecucion',
  templateUrl: './encuesta-ejecucion.component.html',
  styleUrls: ['./encuesta-ejecucion.component.css']
})
export class EncuestaEjecucionComponent implements OnInit {
  public Loading = false;
  private _Encuesta: Encuesta;
  public EncuestaEjecucion: EncuestaResponder;
  @Input() set Encuesta(E) {
    console.log(E);
    this._Encuesta = E;
  }
  @Input() set Guardar(option) {
    console.log(option);
    if(option) {
      this.ContestarEncuesta();
    }
  }
  @Output('EncuestaTerminada') EncuestaTerminada: EventEmitter<any>;
  @Output('EstablecerEncuestaIncompleta') EstablecerEncuestaIncompleta: EventEmitter<any>;

  constructor(
    private SesionSVC: SesionService,
    private EncuestaSVC: EncuestaService,
  ) {
    this.EncuestaTerminada = new EventEmitter<any>();
    this.EstablecerEncuestaIncompleta = new EventEmitter<any>();
  }

  ngOnInit() {
    this.ConsultarEncuestaResponder();
  }

  ConsultarEncuestaResponder() {
    this.Loading = true;
    this.EncuestaSVC.ConsultarResponderEncuesta(this._Encuesta.EncuestaId).subscribe(res => {
      console.log(res);
      this.Loading = false;
      if(!res.Error) {
        this.EncuestaEjecucion = new EncuestaResponder(res.EncuestaEjecucion);
      }
    }, error => this.Loading = false);
  }

  ContestarEncuesta() {
    //this.ObtenerRespuestas.emit(this.EncuestaEjecucion);
    this.EncuestaSVC.ActualizarEjecucionEncuesta(this.EncuestaEjecucion.EncuestaId, this.EncuestaEjecucion.Reactivos).subscribe(res => {
      console.log(res);
      if(!res.Error) {
        this.ActualizarEjecucionCuenta();
      }
      else {
        this.EstablecerEncuestaIncompleta.emit(res);
      }
    })
  }
  public ActualizarEjecucionCuenta() {
    let data = {
      EjecucionCuenta: {
        EjecucionCuentaId: 0,
        EncuestaId: this._Encuesta.EncuestaId,
        Terminada: true,
        CuentaId: this.SesionSVC.Perfil.Cuenta.CuentaId,
        Estado: true
      }
    };

    console.log(data);
    this.EncuestaSVC.ActualizarEjecucionCuenta(data).subscribe(res => {
        console.log(res);
        if(!res.Error) {
          this.EncuestaTerminada.emit(res);
        }
    });
  }

  Responder() {
  }

  OnChangeReactivoResuestaMultiple(e,Reactivo, Respuesta){
    if(e.checked){
      console.log("Agregar respuesta");
      Reactivo.EjecucionReactivoRespuestaMultipleDTO.push({
        EjecucionReactivoRespuestaMultipleId: 0,
        EncuestaReactivoRespuestaId: Respuesta.EncuestaReactivoRespuestaId,
        Estado: true
      });
    }else{
      console.log("Eliminar resppuesta");
      Reactivo.EjecucionReactivoRespuestaMultipleDTO.splice(
        Reactivo.EjecucionReactivoRespuestaMultipleDTO.filter(
          R => R.EncuestaReactivoRespuestaId == Respuesta.EncuestaReactivoRespuestaId
        )
      );
    }
  }
}
