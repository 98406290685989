import { Pipe, PipeTransform } from '@angular/core';
import { CryptoService } from '../services/Utils/crypto.service';

@Pipe({
  name: 'crypto'
})
export class CryptoPipe implements PipeTransform {

  constructor(private cryptoService: CryptoService) {}

  transform(value: any, args?: any): any {
    return this.cryptoService.encrypt(value.toString());
  }

}
