import { Cuenta } from "./Cuenta";
import { PersonaBase } from "./Persona";

export class Profesor extends Cuenta implements PersonaBase {
  CuentaId: number;
  Nombre: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  _NombreCompleto: string;
  CorreoAdicional: string;
  EnSIASE: boolean;
  RolId: number;
  ClaveEmpleado?: string;
  Correo?: string;
  CURP?: string;

  constructor(P) {
    super(P);
    if (P) {
      this.CuentaId = P.CuentaId;
      this.Nombre = P.Nombre;
      this.ApellidoPaterno = P.ApellidoPaterno;
      this.ApellidoMaterno = P.ApellidoMaterno;
      this.CorreoAdicional = P.CorreoAdicional;
      this.EnSIASE = P.EnSIASE;
      this.RolId = P.RolId ? P.RolId : null;
      this.ClaveEmpleado = P.ClaveEmpleado;
      this.Correo = P.Correo;
      this.CURP = P.CURP;
    }
  }

  public NombreCompleto?(Orden?: string) {
    switch(Orden){
      case 'NPM': this._NombreCompleto = `${this.Nombre} ${this.ApellidoPaterno} ${this.ApellidoMaterno}`; break;
      case 'NP':  this._NombreCompleto = `${this.Nombre} ${this.ApellidoPaterno}`; break;
      case 'PMN': this._NombreCompleto = `${this.ApellidoPaterno} ${this.ApellidoMaterno} ${this.Nombre}`; break;
      case 'PN':  this._NombreCompleto = `${this.ApellidoPaterno} ${this.Nombre}`; break;
      default:    this._NombreCompleto = `${this.ApellidoPaterno} ${this.ApellidoMaterno} ${this.Nombre}`; break;
    }

    return this._NombreCompleto;
  }

}
