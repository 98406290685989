import { AvisoDestino } from "./AvisoDestino";

export class Aviso {
  AvisoId: number = 0;
  Titulo: string;
  Mensaje: string;
  FechaInicio: any;
  FechaFin: any;
  Destino: AvisoDestino;
  Destinos: AvisoDestino[] = [];
  EsUrgente: boolean = false;
  EnviarCorreo: boolean = false;
  Permanente: boolean = false;
  Estado: boolean = true;
  Expirado: boolean;
  CreoRol: number = 0;

  constructor(A){
    if (A) {
      this.AvisoId      = A.AvisoId;
      this.Titulo       = A.Titulo;
      this.Mensaje      = A.Mensaje;
      this.FechaInicio  = new Date(A.FechaInicio);
      this.FechaFin     = new Date(A.FechaFin);
      this.Destino      = new AvisoDestino(A.Destino);
      this.Destinos     = A.Destino ? [new AvisoDestino(A.Destino)] : (A.Destinos ? A.Destinos.map( A => new AvisoDestino(A)) : []);
      this.EsUrgente    = A.EsUrgente;
      this.EnviarCorreo = A.EnviarCorreo;
      this.Permanente   = A.Permanente;
      this.Estado       = A.Estado;
      this.Expirado     = A.Expirado;
      this.CreoRol      = A.CreoRol;
    }
  }
}
