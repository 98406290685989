
export class TextEditor {

  constructor() {}

  public CleanFroalaEditor(HTML: string) {
      let Descripcion;
      if (HTML) {
          Descripcion = HTML.replace('<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>', '');
      }
      return Descripcion;
  }

}
