export class Sesion {
  public Token: any;
  private _FechaExpiracion: any;
  private _TiempoSistema: any;

  public TokenExpirado: boolean;
  public TiempoRestante: number; //seconds
  public FechaInicio: any;
  public Tiempo: any;
  public AreaAcademicaId: number;
  public RolId: number;

  constructor(S){
    if (S) {
      this.Token = S.Token;
      this.FechaExpiracion = S.TiempoRestante;
      this.TokenExpirado = S.TokenExpirado;
      this.FechaInicio = new Date();
      this.AreaAcademicaId = S.AreaAcademicaId;
      this.RolId = S.RolId;
      if (S.Tiempo) {
        this.Tiempo = S.Tiempo;
        this.TiempoSistema = S.Tiempo.TiempoSistema;
        this.TiempoRestante = S.Tiempo.Restante;
      }
    }

  }

  /*
  public set Token(Token){
    this._Token = Token;
  }
  public get Token(){
    return this._Token;
  }
  */

  /*
  public get TokenExpirado() {
    return this._NFechaExpiracion > this._NTiempoSistema ? true : false ;
  }*/

  set TiempoSistema(T) {
    this._TiempoSistema = new Date(T);
  }
  get TiempoSistema() {
    return this._TiempoSistema;
  }

  set FechaExpiracion(T){
    this._FechaExpiracion = new Date((new Date(this.FechaInicio).getSeconds() + T)*1000)
    //this._NFechaExpiracion = new Date(new Date().getSeconds() + Tiempo);
  }
  get FechaExpiracion(){
    return this._FechaExpiracion;
  }
}
