import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SnackBarService } from 'src/app/services/Utils/AngularMaterial/SnackBar/SnackBar.service';
import { DateService } from 'src/app/services/Utils/Date/Date.service';
import { EncuestaService } from 'src/app/services/Encuesta/Encuesta.service';
import { SesionService } from 'src/app/services/Sesion/Sesion.service';
import { Encuesta, EncuestaResponder } from 'src/app/models/Encuesta/Encuesta';

@Component({
  selector: 'app-encuesta-responder',
  templateUrl: './encuesta-responder.component.html',
  styleUrls: ['./encuesta-responder.component.css']
})
export class EncuestaResponderComponent implements OnInit {
  public Encuesta: Encuesta;
  public EncuestaResponder: EncuestaResponder;
  public TabIndex = 0;
  public Step = 1;
  public TiempoRestante;
  public EncuestaTimer;
  public EncuestaTerminar = false;
  public Loading = false;
  public LoadingBtn = false;
  constructor(
    private SesionSVC: SesionService,
    public EncuestaSVC: EncuestaService,
    private FechaSVC: DateService,
    private snackbar: SnackBarService,
    private dialogRef: MatDialogRef<EncuestaResponderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.Encuesta = data.Encuesta;
    this.TiempoRestante = this.FechaSVC.GetDiff(new Date(), this.Encuesta.FechaFin, 'seconds');

    dialogRef.beforeClose().subscribe(() => dialogRef.close({
      accion: 'eliminar'
    }));
  }

  ngOnInit() {
    this.EncuestaTimer = {
      leftTime: this.TiempoRestante
    }
    //this.ConsultarEncuesta();
  }

  IniciarEncuesta() {
    this.TabIndex = 1;
    this.Step = 2;
  }
  /*ConsultarEncuesta() {
    this.Loading = true;
    this.EncuestaSVC.ConsultarEncuesta(this.Encuesta.EncuestaId).subscribe(res => {
      console.log(res);
      this.Loading = false;
    }, error => this.Loading = false );
  }*/
  TerminarEncuesta() {
    this.LoadingBtn = true;
    this.EncuestaTerminar = true;
    console.log(this.EncuestaTerminar);
  }
  EncuestaTerminada(e) {
    console.log(e);
    this.TabIndex = 2;
    this.Step = 3;
  }

  EstablecerEncuestaIncompleta() {
    this.EncuestaTerminar = false;
  }

  FechaFormato(Fecha) {
    return this.FechaSVC.FechaFormato(Fecha,1,1);
  }

  OnChangeTab(e) {
    console.log(e);
  }

}
